// react imports
import React, { useState, useEffect } from "react";
import moment from "moment";

// module imports
import {
  AnimatedAxis, // any of these can be non-animated equivalents
  AnimatedGrid,
  AnimatedBarSeries,
  XYChart,
} from "@visx/xychart";

// API imports
import { MachineInfoApi } from "../../pages/machineInfo/repository";

export default function Graph({ machineUuid, filter }) {
  // Code to add custom when hoveriing over a bar:

  // const CustomTick = ({ x, y, payload }) => (
  //   <g transform={`translate(${x},${y})`}>
  //     <text
  //       x={0}
  //       y={0}
  //       dy={6}
  //       textAnchor="end"
  //       fill="#666"
  //       transform="rotate(-90)"
  //     >
  //       {payload.value}
  //     </text>
  //   </g>
  // );

  const [machineTelemetry, setMachineTelemetry] = useState([]);
  const [xAccessorFormat, setXAccessorFormat] = useState("HH:mm A");
  const axisBottomTickLabelProps = {
    textAnchor: "middle",
    fontFamily: "Arial",
    fontSize: 12,
    fill: "#495057",
    angle: -45,
    dy: ".85em",
  };

  useEffect(() => {
    const fetchGraphData = async () => {
      if (filter.id === "TODAY") {
        setXAccessorFormat("HH:mm A");
      } else if (filter.id === "YEAR") {
        setXAccessorFormat("MM-yyyy");
      } else {
        setXAccessorFormat("DD-MM");
      }
      const machineTelemetryResp =
        await MachineInfoApi.getMachineTelemetryLatest(machineUuid, filter);
      setMachineTelemetry(machineTelemetryResp);
    };
    fetchGraphData();
  }, [filter, machineUuid]);

  const accessors = {
    xAccessor: (d) => moment(d.machineTimestamp).format(xAccessorFormat),
    yAccessor: (d) => d.printsValue,
  };
  return (
    <div className="relative">
      <div
        onClick={() => MachineInfoApi.downloadGraph(machineTelemetry)}
        className="cursor-pointer absolute top-0 right-0 mr-2 -mt-14 sm:-mt-12"
      >
        <button
          className="flex items-center text-sm font-medium px-4 py-2 border border-transparent shadow-sm rounded-3xl text-white bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 hover:-translate-1 hover:scale-110 duration-300"
          onClick={() => MachineInfoApi.downloadGraph(machineTelemetry)}
        >
          <div className="mr-1">
            <svg
              width="18"
              height="12"
              viewBox="0 0 18 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5125 4.53C14.0025 1.9425 11.73 0 9 0C6.8325 0 4.95 1.23 4.0125 3.03C1.755 3.27 0 5.1825 0 7.5C0 9.9825 2.0175 12 4.5 12H14.25C16.32 12 18 10.32 18 8.25C18 6.27 16.4625 4.665 14.5125 4.53ZM14.25 10.5H4.5C2.8425 10.5 1.5 9.1575 1.5 7.5C1.5 5.9625 2.6475 4.68 4.17 4.5225L4.9725 4.44L5.3475 3.7275C6.06 2.355 7.455 1.5 9 1.5C10.965 1.5 12.66 2.895 13.0425 4.8225L13.2675 5.9475L14.415 6.03C15.585 6.105 16.5 7.0875 16.5 8.25C16.5 9.4875 15.4875 10.5 14.25 10.5ZM10.0875 4.5H7.9125V6.75H6L9 9.75L12 6.75H10.0875V4.5Z"
                fill="white"
              />
            </svg>
          </div>
          <p className="mr-1">EXPORT</p>
        </button>
      </div>
      <XYChart
        height={500}
        xScale={{ type: "band", paddingInner: 0.8, paddingOuter: 0.5 }}
        yScale={{ type: "linear" }}
      >
        <AnimatedAxis
          orientation="bottom"
          animationTrajectory={"center"}
          hideAxisLine={machineTelemetry.length > 0}
          hideTicksboolean={true}
          tickLabelProps={() => axisBottomTickLabelProps}
        ></AnimatedAxis>
        <AnimatedAxis
          orientation="left"
          animationTrajectory={"center"}
          hideTicksboolean={true}
        />
        <AnimatedGrid columns={false} numTicks={8} />
        <AnimatedBarSeries
          data={
            machineTelemetry.length > 0
              ? machineTelemetry
              : [
                  {
                    machineTimestamp: "2022-05-26T00:00:00.000Z",
                    printsValue: 0,
                  },
                  {
                    machineTimestamp: "2022-05-27T00:00:00.000Z",
                    printsValue: 0,
                  },
                  {
                    machineTimestamp: "2022-05-28T00:00:00.000Z",
                    printsValue: 0,
                  },
                  {
                    machineTimestamp: "2022-05-29T00:00:00.000Z",
                    printsValue: 0,
                  },
                  {
                    machineTimestamp: "2022-05-30T00:00:00.000Z",
                    printsValue: 0,
                  },
                  {
                    machineTimestamp: "2022-05-31T00:00:00.000Z",
                    printsValue: 0,
                  },
                ]
          }
          xAccessor={accessors.xAccessor}
          yAccessor={accessors.yAccessor}
          fill="#00AAFF"
          radius={0}
          radiusTop={true}
        />
      </XYChart>
    </div>
  );
}
