export function storeFeature(item) {
  return window.localStorage.setItem(`Feature${item}`, "true");
}

export function featureEnabled(item) {
  if (
    item.name === "Machines" ||
    process.env[`${item.flag}`] === "true" ||
    window.localStorage.getItem(`Feature${item.name}`) === "true"
  ) {
    return true;
  }
}
