import { apiCall } from "../../api";

export const DeviceApi = {
  async allDevices() {
    const DevicesList = await apiCall("/devices");
    return DevicesList;
  },

  async createDevice(deviceData) {
    const device = await apiCall("/devices/", deviceData, "POST");
    return device;
  },
};
