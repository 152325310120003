// react imports
import { Fragment, useContext, useState } from "react";

// module imports
import { Dialog, Transition } from "@headlessui/react";
import {
  Routes,
  Route,
  NavLink,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { MenuAlt2Icon, XIcon } from "@heroicons/react/outline";

// context imports
import { AuthContext } from "../../contexts/authContext";

// page imports
import routes from "./routes";
import { storeFeature, featureEnabled } from "./featureFlag";
import Profile from "../profile";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
// TODO: Change home to navigation
export default function Home() {
  //context hooks
  const authContext = useContext(AuthContext);
  const { sessionInfo } = authContext;

  // route hooks
  let location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // state hooks
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const routeKeys = Object.keys(routes);

  return (
    <div className="bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment} appear={sidebarOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="block h-10 w-auto"
                    src="/silkwormLogo.png"
                    alt="Workflow"
                  />
                  <p className="ml-2 text-xl font-medium text-primary">
                    Silkworm
                  </p>
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  {routeKeys.map((item) => {
                    const { name, href, flag } = routes[item];
                    const FeatureIcon = routes[item].icon;
                    if (
                      searchParams.get(`${name.toLowerCase()}-feature`) ===
                      "true"
                    ) {
                      storeFeature(name);
                    }
                    if (featureEnabled(routes[item])) {
                      return (
                        <NavLink
                          end
                          key={name}
                          to={href}
                          className={classNames(
                            location.pathname
                              .split("/")
                              .slice(2)
                              .includes(href) ||
                              (href === "/" && location.pathname === "/home")
                              ? "bg-primary text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                          onClick={() => setSidebarOpen(false)}
                        >
                          <FeatureIcon
                            className={classNames(
                              location.pathname
                                .split("/")
                                .slice(2)
                                .includes(href) ||
                                (href === "/" && location.pathname === "/home")
                                ? "text-gray-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {name}
                        </NavLink>
                      );
                    }
                    return null;
                  })}
                </nav>
              </div>
              <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                <a href="/profile" className="flex-shrink-0 w-full group block">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src="/assets/profile.jpeg"
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                        {sessionInfo.silkworm_alvyl_io_userName}
                      </p>
                      <p className="text-xs text-[#00000099]">
                        Go to profile page
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
          <div className="flex-1 flex flex-col pt-2 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4 py-2 border-b border-gray-200">
              <div className="flex-shrink-0 flex items-center">
                <img
                  className="block h-10 w-auto"
                  src="/silkwormLogo.png"
                  alt="Workflow"
                />
                <p className="ml-2 text-xl font-medium text-primary">
                  Silkworm
                </p>
              </div>
            </div>
            <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
              {routeKeys.map((item) => {
                const { name, href } = routes[item];
                const FeatureIcon = routes[item].icon;
                if (
                  searchParams.get(
                    `${routes[item].name.toLowerCase()}-feature`
                  ) === "true"
                ) {
                  storeFeature(routes[item].name);
                }
                if (featureEnabled(routes[item])) {
                  return (
                    <NavLink
                      end
                      key={name}
                      to={href}
                      className={classNames(
                        location.pathname.split("/").slice(2).includes(href) ||
                          (href === "/" && location.pathname === "/home")
                          ? "bg-primary text-gray-900"
                          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                        "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                      )}
                      onClick={() => setSidebarOpen(false)}
                    >
                      <FeatureIcon
                        className={classNames(
                          location.pathname
                            .split("/")
                            .slice(2)
                            .includes(href) ||
                            (href === "/" && location.pathname === "/home")
                            ? "text-gray-500"
                            : "text-gray-400 group-hover:text-gray-500",
                          "mr-4 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {name}
                    </NavLink>
                  );
                }
                return null;
              })}
            </nav>
          </div>
          <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
            <a href="/profile" className="flex-shrink-0 w-full group block">
              <div className="flex items-center">
                <div>
                  <img
                    className="inline-block h-10 w-10 rounded-full"
                    src="/assets/profile.jpeg"
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                    {sessionInfo.silkworm_alvyl_io_userName}
                  </p>
                  <p className="text-xs text-[#00000099]">Go to profile page</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="md:pl-64 flex flex-col flex-1">
        <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white flex flex-row">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-shrink-0 flex items-center px-2">
            <img
              className="block h-8 w-8"
              src="/silkwormLogo.png"
              alt="Workflow"
            />
            <p className="ml-2 text-xl font-medium text-primary">Silkworm</p>
          </div>
        </div>
        {
          <Routes>
            {routeKeys.map((item) => {
              const { name, href, element } = routes[item];
              return (
                <Route path={href + "/*"} element={element} key={name}></Route>
              );
            })}
            <Route path="/profile" element={<Profile />}></Route>
          </Routes>
        }
      </div>
    </div>
  );
}
