import { apiCall } from "../../api";
import moment from "moment";

export const MachineInfoApi = {
  async machineDetails(machineUuid) {
    const machineInfo = await apiCall(`/machines/${machineUuid}?details=all`);
    return machineInfo;
  },

  async machineTelemetryDetails(machineUuid) {
    const machinesTelemetry = await apiCall(
      `/machine-telemetry/${machineUuid}/prints/latest`
    );
    return machinesTelemetry;
  },

  async getPrintCount(machineUuid) {
    const machinePrintCount = await apiCall(
      `/machine-telemetry/${machineUuid}/prints/latest`
    );
    return machinePrintCount;
  },

  async getMachineTelemetryLatest(machineUuid, filter) {
    let period = "hour";
    let from = moment().format("YYYY-MM-DD");
    let to = moment().add(1, "day").format("YYYY-MM-DD");
    switch (filter.id) {
      case "TODAY":
        break;
      case "WEEK":
        period = "day";
        from = moment().subtract(7, "day").format("YYYY-MM-DD");
        break;
      case "MONTH":
        period = "day";
        from = moment().subtract(30, "day").format("YYYY-MM-DD");
        break;
      case "YEAR":
        period = "day";
        from = moment().subtract(365, "day").format("YYYY-MM-DD");
        break;

      default:
        break;
    }

    const machineTelemetryDetails = await apiCall(
      `/machine-telemetry-v2/${machineUuid}/prints?period=${period}&from=${from}&to=${to}`
    );
    return machineTelemetryDetails;
  },

  async downloadGraph(data = []) {
    let csv = "";
    let values =
      (data.length > 0 &&
        data.map((o) => Object.values(o).join(",")).join("\n")) ||
      "";

    csv += "data:text/csv;charset=utf-8" + ",Timestamp,Print Count \n" + values;
    console.log(csv);

    let encodedUri = encodeURI(csv);
    window.open(encodedUri);
  },
};
