export default function DeviceCard({ device }) {
  return (
    <div className="max-w-[384px] rounded-2xl p-4  space-y-4 cursor-pointer transition ease-in-out delay-150 bg-white hover:-translate-y-1 hover:scale-110 duration-300">
      <div className="flex flex-row justify-between items-center">
        <p className="font-normal text-2xl">{device.name}</p>
        <img
          src={device.online ? "/assets/wifi.svg" : "/assets/wifi_inactive.svg"}
          alt="active-status"
        ></img>
      </div>
      <div className="">
        <p className="font-medium text-base">{device.machineUuid}</p>
        <p className="text-xs text-[#00000099]">Device UUID</p>
      </div>
      <div className="">
        <p className="font-medium text-base">{device.machineName}</p>
        <p className="text-xs text-[#00000099]">Machine name</p>
      </div>
    </div>
  );
}
