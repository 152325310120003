//react imports
import { Outlet } from "react-router-dom";
import { useContext } from "react";

//module imports

//context imports
import { AuthContext } from "../contexts/authContext";
import { ToastContext } from "../contexts/toastContext";

//component imports
import Header from "../components/header";
import Footer from "../components/Footer";
import Toaster from "../components/Toaster";

export function Layout() {
  // context
  const authContext = useContext(AuthContext);
  const { authStatus } = authContext;
  const toastContext = useContext(ToastContext);

  return (
    <>
      <Toaster></Toaster>
      <div className="h-full flex flex-col">
        {authStatus !== "SignedIn" && <Header></Header>}
        <div className="h-full">
          <div className="h-full">
            <Outlet />
          </div>
        </div>
        {/* {authStatus !== "SignedIn" && <Footer></Footer>} */}
      </div>
    </>
  );
}
