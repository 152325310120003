//react imports
import { useState, useEffect } from "react";

// module imports
import { PlusIcon } from "@heroicons/react/solid";
import { Link, Outlet, Routes, Route } from "react-router-dom";

// component imports
import MachineForm from "../../components/MachineForm";
import MachineInfo from "../machineInfo";
import MachineCard from "../../components/MachineCard";

// api imports
import { MachineApi } from "./repository";

export default function Machine() {
  const [openForm, setOpenForm] = useState(false);
  const [machinesList, setMachinesList] = useState([]);
  useEffect(() => {
    const fetchMachines = async () => {
      const machineListResp = (await MachineApi.allMachines()) || [];
      setMachinesList(machineListResp);
    };
    setTimeout(() => {
      fetchMachines();
    }, 1000);
  }, []);
  return (
    <Routes>
      <Route path="" element={<Outlet></Outlet>}>
        <Route
          path="/"
          element={
            <main className="flex-1 bg-gray-100 ">
              <div className="py-6 bg-gray-100 h-screen">
                <MachineForm
                  open={openForm}
                  setOpen={setOpenForm}
                ></MachineForm>

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex flex-row justify-between items-center">
                  <h1 className="text-xl font-medium text-gray-900">
                    Machines
                  </h1>
                  <button
                    type="button"
                    className="inline-flex items-center text-sm font-medium px-4 py-2 border border-transparent shadow-sm rounded-3xl text-white bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 hover:-translate-1 hover:scale-110 duration-300"
                    onClick={() => setOpenForm(true)}
                  >
                    <PlusIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                    ADD MACHINE
                  </button>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                  {/* Replace with your content */}
                  <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 mt-4">
                    {machinesList.length > 0 &&
                      machinesList?.map((machine, index) => (
                        <Link
                          to={`${machine.machine_uuid}`}
                          replace
                          key={index}
                        >
                          <li
                            key={index}
                            className="col-span-1 flex flex-col text-center bg-gray-100 rounded-lg divide-y divide-gray-300 border-[1px] drop-shadow-lg shadow-lg"
                          >
                            <MachineCard machine={machine} />
                          </li>
                        </Link>
                      ))}
                    {}
                  </ul>
                  {machinesList.length === 0 && (
                    <div className="text-center my-auto mt-[100px]">
                      <h1 className="text-2xl font-normal text-gray-900">
                        No machines found
                      </h1>
                    </div>
                  )}
                  {/* /End replace */}
                </div>
              </div>
            </main>
          }
        />
        <Route path=":machineUuid" element={<MachineInfo />} />
      </Route>
    </Routes>
  );
}
