// react imports
import React, { useState } from "react";

const defaultState = {
  visible: false,
};

const toastTypes = {
  success: "success",
  error: "error",
};

export const ToastContext = React.createContext(defaultState);

const ToastProvider = ({ children }) => {
  const [visible, setToastVisibility] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  async function toasty(type, message) {
    setMessage(message);
    setType(toastTypes[type]);
    setToastVisibility(true);
    setTimeout(() => setToastVisibility(false), 2000);
  }

  const state = {
    visible,
    message,
    type,
    setToastVisibility,
    toasty,
  };

  return (
    <ToastContext.Provider value={state}>{children}</ToastContext.Provider>
  );
};

export default ToastProvider;
