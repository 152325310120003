import Machine from "../machine";
import Dasboard from "../dashboard";
import Design from "../design";
import Job from "../job";
import Device from "../device";
import Report from "../report";
import Batch from "../batch";

import {
  CalendarIcon,
  ChipIcon,
  PhotographIcon,
  HomeIcon,
  InboxIcon,
  ServerIcon,
  DocumentReportIcon,
} from "@heroicons/react/outline";

const routes = {
  dashboard: {
    name: "Dashboard",
    href: "/home",
    icon: HomeIcon,
    current: true,
    element: <Dasboard />,
    flag: "REACT_APP_FF_DASHBOARD",
  },
  machines: {
    name: "Machines",
    href: "machines",
    icon: ServerIcon,
    current: false,
    element: <Machine />,
    flag: "REACT_APP_FF_MACHINES",
  },
  devices: {
    name: "Devices",
    href: "devices",
    icon: ChipIcon,
    element: <Device />,
    flag: "REACT_APP_FF_DEVICES",
  },
  reports: {
    name: "Reports",
    href: "reports",
    icon: DocumentReportIcon,
    element: <Report />,
    flag: "REACT_APP_FF_REPORTS",
  },
  desgins: {
    name: "Designs",
    href: "designs",
    icon: PhotographIcon,
    current: false,
    element: <Design />,
    flag: "REACT_APP_FF_DESGINS",
  },
  jobs: {
    name: "Jobs",
    href: "jobs",
    icon: CalendarIcon,
    element: <Job />,
    flag: "REACT_APP_FF_JOBS",
  },
  batches: {
    name: "Batches",
    href: "batches",
    icon: InboxIcon,
    element: <Batch />,
    flag: "REACT_APP_FF_BATCHES",
  },
};

export default routes;
