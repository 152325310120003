// react imports
import { useState, useContext } from "react";

// module imports
import { useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";

// context imports
import { AuthContext } from "../../contexts/authContext";
import { ToastContext } from "../../contexts/toastContext";

export default function ForgotPassword() {
  // router hooks
  const navigate = useNavigate();

  // context hooks
  const authContext = useContext(AuthContext);
  const toastContext = useContext(ToastContext);

  // state hooks
  const [username, setUsername] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // functions to handle the forgot password form
  async function handleCodeSend(event) {
    event.preventDefault();
    console.log(username);
    authContext.sendCode(username, (response) => {
      console.log(response, "xxxxxxxxx");
      if (typeof response === "string") {
        toastContext.toasty("error", response);
        return;
      }
      setCodeSent(true);
    });
  }

  function hadlePasswordReset(event) {
    event.preventDefault();
    authContext.forgotPassword(username, code, password);
    navigate("/signIn");
  }

  // form validation
  const disableResetButton = password !== confirmPassword;

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="/silkwormLogo.png"
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Forgot your password?
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form
            className="space-y-6"
            method="POST"
            onSubmit={codeSent ? hadlePasswordReset : handleCodeSend}
          >
            {!codeSent && (
              <>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email or Username
                  </label>
                  <div className="mt-1">
                    <input
                      id="username"
                      name="username"
                      type="username"
                      autoComplete="username"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      placeholder="eg: sanjay@alvyl.com / sanjay-alvyl"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    Send OTP
                  </button>
                </div>
              </>
            )}
            {codeSent && (
              <>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Enter OTP
                  </label>
                  <div className="mt-1">
                    <input
                      id="otp"
                      name="otp"
                      type="otp"
                      autoComplete="otp"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      placeholder="eg: 123456"
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Enter New Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      placeholder="********"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Confirm Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      autoComplete="password"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      placeholder="********"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />

                    {disableResetButton && (
                      <p className="mt-2  text-pink-600 text-sm">
                        Your password and confirmation password do not match.
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:bg-green-200 disabled:cursor-not-allowed"
                    disabled={disableResetButton}
                  >
                    Reset password
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
