// module imports
import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";

export default function Header() {
  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-transparent">
          {({ open }) => (
            <>
              <div className="max-w-10xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between">
                  <Link className="flex" to={"/"}>
                    <div className="flex-shrink-0 flex items-center my-4">
                      <img
                        className="block h-12 w-auto  border-primary border-opacity-60"
                        src="/silkwormLogo.png"
                        alt="Workflow"
                      />
                      <p className="ml-3 text-2xl font-normal text-primary">
                        Silkworm
                      </p>
                    </div>
                  </Link>
                  <div className="hidden sm:ml-6 sm:flex sm:items-center"></div>
                </div>
              </div>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
}
