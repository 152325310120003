// module imports
import { BrowserRouter, Route, Routes } from "react-router-dom";

//context imports
import AuthProvider, { AuthRequired } from "./contexts/authContext";
import ToastProvider from "./contexts/toastContext";

//Page imports
import { Layout } from "./layouts";
import Landing from "./pages/landing";
import Home from "./pages/home";
import ForgotPassword from "./pages/forgotPassword";
import NewPassword from "./pages/newPassword";

export default function App() {
  return (
    <AuthProvider>
      <ToastProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Landing />} />
              <Route path="signIn" element={<Landing />} />
              <Route path="forgotPassword" element={<ForgotPassword />} />
              <Route path="newPassword" element={<NewPassword />} />
              <Route
                path="/*"
                element={
                  <AuthRequired>
                    <Home />
                  </AuthRequired>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </ToastProvider>
    </AuthProvider>
  );
}
