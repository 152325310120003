// react imports
import { useContext } from "react";

// module imports
import { useLocation, useNavigate } from "react-router-dom";

// context imports
import { AuthContext } from "../../contexts/authContext";
import { ToastContext } from "../../contexts/toastContext";

export default function SignIn() {
  // router hooks
  let location = useLocation();
  let navigate = useNavigate();

  //context hooks
  const authContext = useContext(AuthContext);
  const toastContext = useContext(ToastContext);

  // storing the redirect url if not signed in
  let from = location.state?.from?.pathname || "/";

  function handleSignIn(event) {
    event.preventDefault();

    let email = event.target.email.value;
    let password = event.target.password.value;
    authContext.signInWithEmail({ email, password }, (response) => {
      if (typeof response === "string") {
        toastContext.toasty("error", response);
        return;
      }
      if (response.newPasswordRequired) from = "/newPassword";
      navigate(from);
    });
  }

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-4 sm:py-6 px-4 sm:px-10 bg-white rounded-md shadow-md">
        <div className="sm:mx-auto sm:w-full px-2">
          <h2 className="sm:mt-3 text-xl sm:text-3xl font-normal text-gray-900 sm:text-left ">
            Sign In
          </h2>
        </div>

        <div className="mt-2 sm:mx-auto w-full lg:w-full px-2">
          <div className="bg-white py-4 sm:py-4 ">
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={handleSignIn}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-normal text-gray-600"
                >
                  Email address / Username
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="username"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-normal text-gray-600"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <a
                    href="/forgotPassword"
                    className="font-medium text-primary hover:text-primary"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
