// react imports
import { useContext } from "react";

import { useNavigate, useLocation } from "react-router-dom";
// context imports
import { AuthContext } from "../../contexts/authContext";
import routes from "../home/routes";

export default function Profile() {
  //context hooks
  const authContext = useContext(AuthContext);
  const { sessionInfo } = authContext;

  let location = useLocation();
  let navigate = useNavigate();

  let from = location.state?.from?.pathname || `/${routes["machines"].href}`;
  function handleSignOut(event) {
    event.preventDefault();
    authContext.signOut("", () => {
      navigate(from, { replace: true });
    });
  }
  return (
    <main className="flex-1 ">
      <div className="py-8 h-screen">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-normal text-gray-900">Profile</h1>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 ">
          <div className="py-4 sm:mt-16 mt-4">
            <div className="flex items-center justify-center align-middle">
              <div className="w-96">
                <div className="max-w-7xl mx-auto my-4 flex flex-row justify-between items-center">
                  <div className="flex flex-row items-center">
                    <div className="relative flex flex h-20 w-20 mr-4">
                      <img
                        className="flow-row w-20 h-20 rounded-full"
                        src="/assets/profile.jpeg"
                        alt=""
                      />
                      {/* Add profile edit button here  */}
                    </div>
                    <p className="flex flex-row text-xl font-medium ml-0 mr-4">
                      {sessionInfo.silkworm_alvyl_io_userName}
                    </p>
                  </div>
                  <div className="flex flex-row justify-end cursor-pointer mr-0">
                    {/* Add Edit button here */}
                  </div>
                </div>
                <div className="flex flex-col  rounded-lg bg-white divide-y divide-gray-300">
                  <div>
                    <div className="flex flex-col justify-start space-x-5">
                      <div className="m-5">
                        <p className="text-left text-base">
                          {sessionInfo.silkworm_alvyl_io_mobile}
                        </p>
                        <p className="text-xs text-[#00000099] text-left">
                          Contact number
                        </p>
                      </div>
                    </div>
                    <div className="m-5">
                      <p className="text-left text-base">
                        {sessionInfo.silkworm_alvyl_io_email}
                      </p>
                      <p className="text-xs text-[#00000099] text-left">
                        Email id
                      </p>
                    </div>
                  </div>
                  {/* This is change password button */}
                  {/* <div>
                    <p className="m-5 text-left text-primary text-sm font-medium cursor-pointer">
                      CHANGE PASSWORD
                    </p>
                  </div> */}
                </div>
                <button
                  className="flex flex-col my-4 w-full h-11 text-white items-center text-sm font-medium justify-center rounded-lg bg-red bg-opacity-70"
                  onClick={handleSignOut}
                >
                  SIGN OUT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
