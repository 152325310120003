// react imorts
import { useState, useEffect, Fragment } from "react";

// module imports
import { useParams, useNavigate } from "react-router-dom";
import {
  CheckIcon,
  PaperClipIcon,
  ThumbUpIcon,
  UserIcon,
  ArrowLeftIcon,
} from "@heroicons/react/solid";

// component imports
import DeviceForm from "../../components/DeviceForm";

// API imports
import { DeviceInfoApi } from "./repository";

const eventTypes = {
  applied: { icon: UserIcon, bgColorClass: "bg-gray-400" },
  advanced: { icon: ThumbUpIcon, bgColorClass: "bg-blue-500" },
  completed: { icon: CheckIcon, bgColorClass: "bg-green-500" },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DeviceInfo() {
  const { deviceUuid } = useParams();
  const [deviceInfo, setDeviceInfo] = useState({});
  const [openForm, setOpenForm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDeviceInfo = async () => {
      const deviceInfoResp = await DeviceInfoApi.deviceDetails(deviceUuid);
      setDeviceInfo(deviceInfoResp);
      // console.log(deviceInfoResp);
      // console.log(deviceUuid);
    };
    fetchDeviceInfo();
    // console.log(deviceUuid);
  }, [deviceUuid, openForm]);

  const deleteDevice = async () => {
    await DeviceInfoApi.deleteDevice(deviceUuid);
    navigate("/devices");
  };
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date(deviceInfo.updatedAt).toLocaleDateString(
    "en-US",
    options
  );
  return (
    <main className="py-6 h-screen">
      {/* Page header */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <button
          onClick={() => navigate("/devices")}
          className="flex flex-row justify-center items-center mb-2"
        >
          <ArrowLeftIcon
            className="h-5 w-5 align-middle mr-1"
            aria-hidden="true"
          />
          <p>{`Back to devices`}</p>
        </button>
      </div>

      <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
        <div className="flex items-center space-x-5">
          <div>
            <h2 className="text-xl font-semibold text-gray-900">
              {deviceInfo.name}
            </h2>
            <p className="text-sm font-medium text-gray-500">
              Attched to machine{" "}
              <a href="#" className="text-gray-900">
                {deviceInfo?.machineName}
              </a>{" "}
              {/* on <time dateTime="2020-08-25">August 25, 2020</time> */}
              on <time dateTime="2020-08-25">{date}</time>
            </p>
          </div>
        </div>
        <div className="hidden sm:flex mt-6 flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          <button
            className="inline-flex items-center text-sm font-medium px-4 py-2 border border-transparent shadow-sm rounded-3xl text-white bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 hover:-translate-1 hover:scale-110 duration-300"
            onClick={() => setOpenForm(true)}
          >
            <div className="mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
            </div>
            <p className="mr-2">EDIT</p>
          </button>
          <button
            type="button"
            className="inline-flex items-center text-sm font-medium px-4 py-2 border border-transparent shadow-sm rounded-3xl text-white bg-red bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 hover:-translate-1 hover:scale-110 duration-300"
            onClick={() => deleteDevice()}
          >
            REMOVE DEVICE
          </button>
        </div>
      </div>

      <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
          {/* Description list*/}
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h2
                  id="applicant-information-title"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  Device Health
                </h2>
                <div className="py-4">
                  <p className="font-medium text-sm">{deviceInfo.deviceUuid}</p>
                  <p className="text-xs text-[#00000099]">Device UUID</p>
                </div>
                <div className="py-4">
                  <p className="font-medium text-sm">
                    {deviceInfo.machineName}
                  </p>
                  <p className="text-xs text-[#00000099]">Machine name</p>
                </div>
                <div className="py-4">
                  <p className="font-medium text-sm">
                    {deviceInfo.machineUuid}
                  </p>
                  <p className="text-xs text-[#00000099]">Machine UUID</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="flex sm:hidden mt-6 mx-4 flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        <button
          className="inline-flex items-center text-white justify-center bg-primary border border-transparent focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-3xl text-sm px-4 py-2.5 text-center dark:border-primary dark:focus:ring-primary"
          onClick={() => setOpenForm(true)}
        >
          <div className="mr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </div>
          <p className="mr-2">EDIT</p>
        </button>
        <button
          type="button"
          className="inline-flex items-center justify-center px-4 py-2.5 border border-transparent text-sm font-medium rounded-3xl shadow-sm text-white bg-red bg-opacity-70 hover:bg-red hover:bg-opacity-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray focus:bg-opacity-100 focus:ring-blue-500"
          onClick={() => deleteDevice()}
        >
          REMOVE DEVICE
        </button>
      </div>
      <DeviceForm
        open={openForm}
        setOpen={setOpenForm}
        deviceInfo={deviceInfo}
        type="edit"
      ></DeviceForm>
    </main>
  );
}
