//react imports
import { useState, useEffect } from "react";

// module imports
import { PlusIcon } from "@heroicons/react/solid";
import { Outlet, Routes, Route, Link } from "react-router-dom";

// api imports
import { DeviceApi } from "./repository";

// component imports
import DeviceForm from "../../components/DeviceForm";
import DeviceInfo from "../deviceInfo";
import DeviceCard from "../../components/DeviceCard";

// api imports

export default function Machine() {
  const [openForm, setOpenForm] = useState(false);
  const [devicesList, setDevicesList] = useState([]);
  useEffect(() => {
    const fetchDevices = async () => {
      const deviceListResp = await DeviceApi.allDevices();
      setDevicesList(deviceListResp?.["items"] || []);
    };
    fetchDevices();
  }, [openForm]);
  return (
    <Routes>
      <Route path="" element={<Outlet></Outlet>}>
        <Route
          path="/"
          element={
            <main className="flex-1 bg-gray-100">
              <div className="py-6 bg-gray-100 h-[850px]">
                <DeviceForm
                  open={openForm}
                  setOpen={setOpenForm}
                  setDevicesList={setDevicesList}
                  devicesList={devicesList}
                  type="create"
                ></DeviceForm>

                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex flex-row justify-between items-center">
                  <h1 className="text-xl font-medium text-gray-900">Devices</h1>
                  <button
                    type="button"
                    className="inline-flex items-center text-sm font-medium px-4 py-2 border border-transparent shadow-sm rounded-3xl text-white bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 hover:-translate-y-1 hover:scale-110 duration-300"
                    onClick={() => setOpenForm(true)}
                  >
                    <PlusIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                    ADD DEVICE
                  </button>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                  {/* Replace with your content */}
                  <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 mt-4">
                    {devicesList?.map((device, index) => (
                      // console.log(device, index),
                      <Link to={`${device?.deviceUuid}`} replace key={index}>
                        {/* <DeviceCard
                              device={device}
                              key={index}
                            ></DeviceCard> */}

                        <DeviceCard device={device} key={index} />
                      </Link>
                    ))}
                    {}
                  </ul>

                  {/* /End replace */}
                </div>
              </div>
            </main>
          }
        />
        <Route path=":deviceUuid" element={<DeviceInfo />} />
      </Route>
    </Routes>
  );
}
