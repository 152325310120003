import { apiCall } from ".";

export const getUploadURL = async () => {
  const response = await apiCall(`/photos/getUploadUrl?resourceType=image/png`);
  if (response) {
    return response;
  }
  console.error(response.statusText);
  return null;
};

export const getDownloadURL = async (path) => {
  const response = await apiCall(`/photos/getDownloadUrl?path=${path}`);
  if (response) {
    return response;
  }
  console.error(response.statusText);
  return null;
};

export const uploadImage = async (uploadUrl, machineImage) => {
  const response = await fetch(uploadUrl, {
    crossdomain: true,
    method: "PUT",
    body: machineImage,
  });
  return response;
};
