// react imports
import { useEffect, useState } from "react";

// API imports
import { getDownloadURL } from "../../api/imageApi";
import { getPrintCounts } from "./repository";

export default function MachineCard({ machine }) {
  const [machineImage, setMachineImage] = useState(null);
  const [printCount, setPrintCount] = useState(0);
  useEffect(() => {
    const fetchImage = async () => {
      if (machine["model"].model_image) {
        const image = await getDownloadURL(machine["model"].model_image);
        setMachineImage(image.url);
      } else {
        setMachineImage(
          `https://av-silkworm-machine-images-snd.s3.amazonaws.com/machine-images/roqprinteco.png`
          // TODO: This needs to come from environment variables during build.
        );
      }
    };
    const fetchPrintCount = async () => {
      const printCount = await getPrintCounts(machine.machine_uuid);
      setPrintCount(
        (printCount.prints_value || 0) - (machine.initial_print_value || 0)
        // TODO: prints_value and initial_print_value to be changed to camelCase in API response.
      );
    };
    fetchImage();
    fetchPrintCount();
  }, [machine.image]);

  return (
    <div className="flex-1 flex flex-col px-6 py-2 border-gray-200 shadow rounded-lg transition ease-in-out delay-150 bg-white hover:-translate-y-1 hover:scale-110 hover:bg-white duration-300">
      <div className="py-1 ">
        <div className="flex flex-row justify-between">
          <p className="font-normal text-2xl">{machine.name}</p>

          <div className="flex flex-row items-center justify-start">
            <span className="flex h-3 w-3 relative mr-1">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75 "></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
            </span>
            <p className="text-sm font-medium text-primary relative">
              {`Online`}
            </p>
          </div>
        </div>
        <div className="flex flex-row justify-start my-2 space-x-5">
          <div>
            <p className="text-left">{printCount}</p>
            <p className="text-xs text-[#00000099] text-left">Print count</p>
          </div>
          <div>
            <p className="text-left">First Floor</p>
            <p className="text-xs text-[#00000099] text-left">Location</p>
          </div>
        </div>
      </div>

      <img
        className="w-full h-40 flex-shrink-0 mx-auto"
        src={machineImage}
        alt=""
      />
    </div>
  );
}
