// react imports
import { useContext } from "react";

// module imports
import { Navigate } from "react-router-dom";

// context imports
import { AuthContext } from "../../contexts/authContext";

// routes import
import routes from "../home/routes";

// component imports
import SignIn from "../signIn";
import Footer from "../../components/Footer";

export default function Landing() {
  // context config
  const authContext = useContext(AuthContext);
  const { authStatus } = authContext;

  return (
    <>
      {authStatus === "SignedIn" ? (
        <Navigate to={`/${routes["machines"].href}`} replace></Navigate> // redirect to home if signed in
      ) : (
        <div className="bg-transparent flex flex-col lg:flex-row h-max w-full">
          <div className="flex flex-col bg-transparent lg:w-1/2 h-max">
            <div className="text-5xl sm:text-6xl font-extralight font-roboto px-4 sm:px-8">
              A one stop <br />
              <span className="text-primary">IoT solution</span>
            </div>
            <div className="text-base sm:text-lg md:text-xl font-normal text-gray-500 mt-2 hidden sm:flex  px-4  sm:px-8">
              An end-to-end IoT solution to collect, manage, and monitor <br />{" "}
              your textile industry machine’s data.
            </div>
            <div className="text-base sm:text-lg md:text-xl font-normal text-gray-500 mt-2 sm:hidden  px-4  sm:px-8">
              An end-to-end IoT solution to collect, manage, and monitor your
              textile industry machine’s data.
            </div>
            <div className="w-[640px] hidden md:flex">
              <img src="./assets/landingPage.png" alt="metrics"></img>
            </div>
          </div>
          <div className="flex flex-col mx-auto my-auto mt-4 md:mt-10 w-full md:w-10/12 lg:w-5/12 px-4 h-max">
            <SignIn></SignIn>
            <div className="mt-2 lg:mt-10">
              <Footer></Footer>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
