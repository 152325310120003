/**
 * Make FETCH calls to Backend and return response as json
 */
export const apiBaseUrl = `https://` + process.env.REACT_APP_REST_API_DOMAIN;
export const apiCall = async (path, body = {}, method = "GET") => {
  if (path) {
    const token = await window.localStorage.getItem(
      "silkworm_alvyl_io_idToken"
    );
    const endpoint = `/${path}`.replaceAll("//", "/"); // --handle '/' prefix if supplied
    const apiData = {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    if (method === "POST" || method === "PATCH") {
      apiData.body = body && JSON.stringify(body);
    }
    return fetch(apiBaseUrl + endpoint, apiData)
      .then((response) => response.json())
      .then(async (responseJSON) => {
        if (
          responseJSON.statusCode === 401 &&
          responseJSON.message === "Unauthorized"
        ) {
          return "Redirect to login page";
        } else {
          return responseJSON;
        }
      })
      .catch((error) => {
        return error;
      });
  } else {
    return `Path is empty`;
  }
};
