// react imports
import { Fragment, useRef, useState, useEffect } from "react";

// module imports
import { Dialog, Transition } from "@headlessui/react";

import { DeviceApi } from "../../pages/device/repository";

export default function DeviceForm({
  open,
  setOpen,
  deviceInfo,
  type,
  setDevicesList,
  devicesList,
}) {
  const cancelButtonRef = useRef(null);
  const initialFormData = Object.freeze({
    machineName: deviceInfo?.machineName || "",
    name: deviceInfo?.name || "",
    deviceUuid: deviceInfo?.id || "",
    machineUuid: deviceInfo?.machineId || "",
  });
  const [formData, updateFormData] = useState(initialFormData);

  useEffect(() => {
    updateFormData(initialFormData);
  }, [deviceInfo]);

  // functions
  const handleChange = (e) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    type === "create"
      ? setDevicesList([...devicesList, await DeviceApi.createDevice(formData)])
      : await DeviceApi.updateDevice(deviceInfo.id, formData);
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form
                className="space-y-8 divide-y divide-gray-200"
                onSubmit={handleSubmit}
              >
                <div className="space-y-8 divide-y divide-gray-200">
                  <div>
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Device Information
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        This information will be displayed on the Device Info
                        page.
                      </p>
                    </div>

                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-4">
                        <label
                          htmlFor="machine-uuid"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Device Name
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="name"
                            className="flex-1 focus:ring-green-500 focus:border-green-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            onChange={handleChange}
                            defaultValue={deviceInfo?.name || ""}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-4">
                        <label
                          htmlFor="machine-uuid"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Device Uuid
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="deviceUuid"
                            id="device-uuid"
                            autoComplete="device-uuid"
                            className="flex-1 focus:ring-green-500 focus:border-green-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            onChange={handleChange}
                            defaultValue={deviceInfo?.deviceUuid || ""}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-4">
                        <label
                          htmlFor="machine-name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Machine Name
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="machineName"
                            id="machine-name"
                            autoComplete="machine-name"
                            className="flex-1 focus:ring-green-500 focus:border-green-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            onChange={handleChange}
                            defaultValue={deviceInfo?.machineName || ""}
                            placeholder="Machine Name to which device is connected"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-4">
                        <label
                          htmlFor="machine-uuid"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Machine Uuid
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            type="text"
                            name="machineUuid"
                            id="machine-uuid"
                            autoComplete="machine-uuid"
                            className="flex-1 focus:ring-green-500 focus:border-green-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                            onChange={handleChange}
                            defaultValue={deviceInfo?.machineUuid || ""}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-3xl shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 hover:-translate-1 hover:scale-110 duration-300"
                      onClick={() => setOpen(false)}
                    >
                      CANCEL
                    </button>
                    <button
                      type="submit"
                      className="ml-3 inline-flex justify-center text-sm font-medium py-2 px-4 border border-transparent shadow-sm  rounded-3xl text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 hover:-translate-1 hover:scale-110 duration-300"
                    >
                      CREATE
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
