export default function Footer() {
  return (
    <footer className="bg-transparent">
      <div className="mx-4 sm:mx-8 text-center">
        <p className="text-xs sm:text-sm font-medium my-2">
          <span className="italic">
            Alvyl Cocoon Cloud Platform by{" "}
            <a
              className="underline text-[#00AAFF]"
              href="https://www.alvyl.com"
              target={"_blank"}
              rel="noreferrer"
            >
              <span>Alvyl Consulting</span>
            </a>{" "}
            {"\u00a9"}.
          </span>
        </p>
      </div>
    </footer>
  );
}
