import { apiCall } from "../../api";

export const MachineApi = {
  async allMachines() {
    let machinesList = await apiCall("/machines");
    return machinesList;
  },

  async createMachine(machineData) {
    const machine = await apiCall("/machines", machineData, "POST");
    return machine;
  },
};
